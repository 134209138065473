import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './Table.module.css'
import { useTranslation } from 'react-i18next'
import Link, { navigate } from '../Link'
import * as currenciesActions from '../../store/actions/currenciesActions'
import { createTheme } from '@mui/material/styles'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { useStaticQuery, graphql } from 'gatsby'
import makeStyles from '@mui/styles/makeStyles'
import { Spinner } from 'react-bootstrap'
import { setTableRows } from '../../store/actions/userActions'
import WithTheme from '../../helpers/WithTheme'
import MiniChartRangeSelector from './MiniChartRangeSelector'

const MUIDataTable = loadable(() => import('mui-datatables'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))

function createData(
  Favorites,
  currencyLogo,
  Alphabetical,
  code,
  BtcPrice,
  percentChange,
  Volume24HBtc,
  MarketCap,
  slug
) {
  return {
    Favorites,
    currencyLogo,
    Alphabetical,
    code,
    BtcPrice,
    percentChange,
    Volume24HBtc,
    MarketCap,
    slug,
  }
}

const useStyles = makeStyles(theme => ({
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      //   backgroundColor:'red',
    },
  },
  centerHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //   backgroundColor:'red',
    },
  },
}))

const CurrenciesTable = () => {
  const dispatch = useDispatch()
  const { t, i18n, ready } = useTranslation(['tables'], { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const userExchRate = useSelector(state => state.user.exchRate)
  const userCurrency = useSelector(state => state.user.selectedCurr)
  const currSign = useSelector(state => state.user.currSign)
  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const currencies = useSelector(state => state.currencies.currencies)
  const isLoading = useSelector(state => state.currencies.loading)
  const [pageNumber, setPageNumber] = useState(1)
  // const [pageSize, setPageSize] = useState(50);
  const tableRows = useSelector(state => state.user.tableRows)
  const [sortBy, setSortBy] = useState('MarketCap')
  const [sortOrder, setSortOrder] = useState('desc')
  const [searchTerm, setSearchTerm] = useState(null)
  const [miniChartRange, setMiniChartRange] = useState('SevenDay')

  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  useEffect(() => {
    dispatch(
      currenciesActions.fetchCurrencies(
        searchTerm,
        sortBy,
        sortOrder,
        tableRows,
        pageNumber,
        miniChartRange
      )
    )
    const interval = setInterval(() => {
      dispatch(
        currenciesActions.fetchCurrencies(
          searchTerm,
          sortBy,
          sortOrder,
          tableRows,
          pageNumber,
          miniChartRange
        )
      )
    }, 90000)
    return () => clearInterval(interval)
  }, [
    dispatch,
    searchTerm,
    sortBy,
    sortOrder,
    tableRows,
    pageNumber,
    miniChartRange,
  ])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const classes = useStyles()

  const columns = [
    {
      name: 'Favorites',
      label: `${t('rank')}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: 'right',
          style: {
            opacity: 0.6,
            width: '1rem',
          },
        }),
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
      },
    },
    {
      name: 'currencyLogo',
      label: `${t('logo')}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            paddingRight: 0,
            background: userDarkMode ? '#191B20' : 'white',
          },
        }),
        customHeadRender: ({ index, ...column }) => {
          return <TableCell key={index}></TableCell>
        },
      },
    },
    {
      name: 'Alphabetical',
      label: `${t('name')}`,
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            fontWeight: 700,
            fontSize: 16,
            position: 'sticky',
            left: '0',
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: 'code',
      label: `${t('code')}`,
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            opacity: 0.6,
          },
        }),
      },
    },
    {
      name: 'BtcPrice',
      label: `${t('price')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'percentChange',
      label: `${t(`${miniChartRange}`)}`,
      options: {
        filter: false,
        sort: true,
        // setCellHeaderProps: () => ({
        //     className: classes.centerHeader,
        // }),
        setCellProps: () => ({
          style: {
            paddingTop: 6,
            paddingBottom: 6,
            color: userDarkMode ? '#599273' : '#2D844B',
          },
        }),

        setCellHeaderProps: () => ({
          className: classes.centerHeader,
        }),
      },
    },
    {
      name: 'Volume24HBtc',
      label: `${t('volume24')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'MarketCap',
      label: `${t('marketCap')}`,
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     }
        //      else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'slug',
      label: '',
      options: {
        display: false,
        viewColumns: false,
      },
    },
  ]

  let data = []
  const [filteredCurrs, setFilteredCurrs] = useState([])
  let totalRecords =
    typeof currencies[0] !== 'undefined' ? currencies[0].totalRecords : 100

  const options = {
    filter: false,
    download: false,
    print: false,
    serverSide: true,
    viewColumns: false,
    count: totalRecords ? totalRecords : 1,
    rowsPerPageOptions: [10, 30, 50, 100],
    customToolbar: () => (
      <MiniChartRangeSelector 
        setMiniChartRange={setMiniChartRange} 
        miniChartRange={miniChartRange}
        loading={isLoading}
      />
    ),
    onTableChange: (action, tableState) => {
      // console.log('ACTION', action, 'STATE', tableState)
      switch (action) {
        case 'changePage':
          setPageNumber(tableState.page + 1)
          break
        case 'changeRowsPerPage':
          // setPageSize(tableState.rowsPerPage);
          dispatch(setTableRows(tableState.rowsPerPage))
          break
        case 'sort':
          setSortBy(tableState.sortOrder.name)
          setSortOrder(tableState.sortOrder.direction)
          break
        case 'search':
          tableState.searchText !== null && tableState.searchText.length > 2
            ? setSearchTerm(tableState.searchText.replace(/\s+/g, ''))
            : setSearchTerm(null)
          break
        case 'onSearchClose':
          setFilteredCurrs([])
          break
        default:
          return
      }
    },
    textLabels: {
      body: {
        noMatch: completed ? (
          t('noMatchCurrencies')
        ) : (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ),
        toolTip: t('sort'),
        columnHeaderTooltip: column => `${t('sortBy')} ${column.label}`,
      },
      toolbar: {
        search: t('search'),
        // downloadCsv: t('downloadCSV'),
        // print: t('print'),
        // viewColumns: t('viewColumns'),
        filterTable: t('filter'),
      },
      pagination: {
        next: t('nextPage'),
        previous: t('previousPage'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('of'), // 1-10 of 30
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('showColumnsAria'),
      },
    },

    filterType: 'multiselect',
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: 'none',
    customRowRender: data => {
      // let marketNameLowCase = data[10].toLowerCase();
      let currSlug = data[8]

      const handleRowClick = () => navigate(`/${lang}/cryptocurrencies/${currSlug}/`)
      return (
        <TableRow onClick={handleRowClick} hover={true} key={Math.random()}>
          <TableCell style={{ textAlign: 'right' }}>
            <Link aria-label={currSlug} to={`/${lang}/cryptocurrencies/${currSlug}/`}>
              <span
                style={{
                  color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
                }}
              >
                {data[0]}
              </span>
            </Link>
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              width: 24,
            }}
          >
            {data[1]}
          </TableCell>
          <TableCell
            style={{
              fontWeight: 700,
              fontSize: 16,
              paddingRight: 0,
            }}
          >
            {data[2]}
          </TableCell>
          <TableCell
            style={{
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: '0',
              zIndex: 100,
              background: userDarkMode ? 'black' : 'rgb(248, 249, 250)',
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[3]}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: 'tabular-nums',
            }}
          >
            {data[4] !== null && data[4] >= 1
              ? currSign +
                ' ' +
                data[4].toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : data[4] !== null && data[4] === 0
              ? '-'
              : data[4] !== null
              ? currSign +
                ' ' +
                data[4].toLocaleString(undefined, {
                  minimumFractionDigits: 8,
                })
              : '-'}
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            {data[5]}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              minWidth: 200,
              fontVariant: 'tabular-nums',
              paddingLeft: 0,
            }}
          >
            {data[6] !== null && data[6] >= 1
              ? currSign +
                ' ' +
                data[6].toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : data[6] !== null && data[6] === 0
              ? '-'
              : data[6] !== null
              ? currSign +
                ' ' +
                data[6].toLocaleString(undefined, {
                  minimumFractionDigits: 8,
                })
              : '-'}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              minWidth: 200,
              fontVariant: 'tabular-nums',
              paddingLeft: 0,
            }}
          >
            {data[7] !== null && data[7] >= 1
              ? currSign +
                ' ' +
                data[7].toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : data[7] !== null && data[7] === 0
              ? '-'
              : data[7] !== null
              ? currSign +
                ' ' +
                data[7].toLocaleString(undefined, {
                  minimumFractionDigits: 8,
                })
              : '-'}
          </TableCell>
        </TableRow>
      )
    },
    rowsPerPage: tableRows,
  }

  filteredCurrs.length > 0
    ? filteredCurrs.map(function (item, index) {
        return data.push(
          createData(
            (pageNumber - 1) * tableRows + index + 1,
            item.currencyLogo,
            item.Alphabetical,
            item.code,
            item.BtcPrice,
            item.percentChange,
            item.Volume24HBtc,
            item.MarketCap,
            item.slug
          )
        )
      })
    : currencies
    ? currencies.map(function (item, index) {
        let currencyLogo = (
          <img
            width="20"
            height="20"
            className={styles.exchangeImg}
            src={process.env.WWW_URL + `/assets/img/currency/${item.code}.webP`}
            alt={`${item.name}`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.webP`
            }}
          />
        )

        let price = btcExchRate
          ? item.lastPriceBtc * btcExchRate
          : item.lastPriceBtc
        let volume24 = btcExchRate
          ? item.volume24h * btcExchRate
          : item.volume24h

        let mktCapValue = userExchRate
          ? item.marketCap * userExchRate
          : item.marketCap

        let lineChartData = []

        let percentageChange = item.percentChange * 100

        typeof item.chartData !== 'undefined' && item.chartData !== null
          ? item.chartData.map(function (candle) {
              return lineChartData.push(candle.price)
            })
          : (lineChartData = [])

        let last7Days = (
          <div className="d-flex justify-content-center">
            <Sparklines
              svgWidth={80}
              svgHeight={20}
              data={lineChartData}
              style={{ marginRight: 10 }}
            >
              <SparklinesLine
                style={{ strokeWidth: 6, fill: 'none' }}
                color={
                  percentageChange >= 0
                    ? userDarkMode
                      ? '#50AF7B'
                      : '#2D844B'
                    : userDarkMode
                    ? '#FB4C42'
                    : '#D0433B'
                }
              />
            </Sparklines>
            <p
              style={{
                fontVariant: 'tabular-nums',
                color:
                  percentageChange >= 0
                    ? userDarkMode
                      ? '#50AF7B'
                      : '#2D844B'
                    : userDarkMode
                    ? '#FB4C42'
                    : '#D0433B',
                fontSize: '14px',
                lineHeight: '20px',
                marginBottom: 0,
              }}
            >
              {typeof percentageChange !== 'undefined'
                ? percentageChange.toFixed(2)
                : 0}
              %
            </p>
          </div>
        )
        return data.push(
          createData(
            (pageNumber - 1) * tableRows + index + 1,
            currencyLogo,
            item.name,
            item.code,
            price,
            last7Days,
            volume24,
            // currSign + ' ' + volume1.toLocaleString(undefined,{ minimumFractionDigits: 2 }),
            mktCapValue !== null ? mktCapValue : '',
            item.slug
          )
        )
      })
    : (data = [])

  return <MUIDataTable data={data} columns={columns} options={options} />
}
const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
          },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          label: { color: userDarkMode ? '#FAFAFA' : '#191B20' },
          title: { color: userDarkMode ? '#FAFAFA' : '#191B20' },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1) !important'
                : 'rgba(0, 0, 0, 0.04) !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontFamily: 'CircularStd, sans-serif', margin: '20px 0' },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:before': { borderBottom: '2px solid transparent !important' },
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
          input: {
            backgroundColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)',

            paddingTop: 14,
            paddingRight: 16,
            paddingBottom: 15,
            paddingLeft: 16,
            borderRadius: 6,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          searchIcon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
          searchText: { maxWidth: 400 },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          caption: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: userDarkMode ? '#FAFAFA' : '#191B20',

            opacity: 0.6,
          },
          select: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: '#3377C6',
            '&:focus': { borderRadius: '10px' },
            backgroundColor: 'transparent',
          },
          selectLabel: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          displayedRows: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          selectIcon: { color: '#3377C6' },
          actions: {
            color: userDarkMode ? '#3377C6' : '#3377C6',
            backgroundColor: 'transparent',
          },
          root: { zIndex: 999 },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif' },
          paper: {
            borderRadius: 0,
            overflow: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
          },
          toolButton: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
            marginRight: '-8px',
            fontWeight: 300,
          },
          fixedHeader: {
            backgroundColor: 'transparent',
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',

            fontWeight: 300,
          },
          data: { color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)' },
          sortActive: {
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          contentWrapper: {},
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56) !important'
              : 'rgba(0, 0, 0, 0.56) !important',
            marginTop: '3px',
            marginRight: '0',
          },
        },
      },
      MuiSelected: {
        styleOverrides: {
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': { color: '#3377C6' },
          },
        },
      },
      MuiButton: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiTableFooter: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif', fontWeight: 300 },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            minHeight: '80px !important',
            paddingLeft: '0px',
            paddingRight: '0px',
            zIndex: 999,
          },
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': { color: '#3377C6' },
          },
          titleText: {
            color: userDarkMode ? '#3986F7' : '#185398',
            fontWeight: 900,
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '24px !important',
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
          },
          actions: {
            display: 'flex',
            flex: 'initial',
            '& > span, & > button': { order: 99 },
            '& > span:last-child, & > button:last-child': { order: 1 },
            '& > span:nth-child(4), & > button:nth-child(4)': { order: 2 },
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: { headerCell: { backgroundColor: 'transparent' } },
      },
      MuiTableCell: {
        styleOverrides: {
          head: { fontWeight: 300 },
          body: {
            fontSize: '14px',
            fontFamily: 'CircularStd, sans-serif',
            cursor: 'pointer',
            color: userDarkMode ? '#FAFAFA' : 'black',
          },
          root: {
            borderBottomColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1) !important'
              : 'light',
            fontFamily: 'CircularStd, sans-serif',
            color: userDarkMode ? '#FAFAFA' : '#191B20',

            fontWeight: 300,
          },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(CurrenciesTable)
